import { render, staticRenderFns } from "./contacto.vue?vue&type=template&id=00de2b38&scoped=true"
import script from "./contacto.vue?vue&type=script&lang=js"
export * from "./contacto.vue?vue&type=script&lang=js"
import style0 from "./contacto.vue?vue&type=style&index=0&id=00de2b38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00de2b38",
  null
  
)

export default component.exports