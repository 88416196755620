<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ failed, valid }">
      <form
        class="formulario row-between fww"
        @submit.prevent="validate"
        :valid="valid"
        autocomplete="off"
        id="formulario"
      >
        <template v-for="(input, key) in $t('contact.form.inputs')">
          <div
            v-if="!input.view || input.view === $route.name"
            :class="
              input.middle
                ? 'contenedor-input -middle waypoints'
                : 'contenedor-input waypoints'
            "
            :key="key"
          >
            <ValidationProvider
              :rules="input.rules"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <textarea
                v-if="input.type == 'textarea'"
                :type="input.type"
                :placeholder="input.placeholder"
                v-model="data[input.id]"
                :class="classes"
              ></textarea>
              <input
                v-else
                :type="input.type"
                :placeholder="input.placeholder"
                v-model="data[input.id]"
                :class="classes"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div
            v-if="input.type == 'checkbox'"
            class="contenedor-check waypoints"
            :key="input.id"
          >
            <ValidationProvider
              :rules="input.rules"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                :type="input.type"
                :id="input.id"
                v-model="data[input.id]"
                :class="classes"
              />
              <label :for="input.id" v-html="input.label"></label>
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </template>

        <div v-html="$t('contact.form.legal_text')"></div>

        <button class="boton-verde waypoints" :loading="loading" v-if="!sent">
          {{ $t("contact.form.btn_text") }}
        </button>
      </form>
    </ValidationObserver>

    <div class="cover-popup row-center" v-if="sent">
      <div class="popup">
        <a class="cerrar" @click="sent = false">✕</a>
        <h2>{{ $t("contact.form.success_popup.title") }}</h2>
        <div v-html="$t('contact.form.success_popup.text')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "formulario-contacto",
  data: () => ({
    data: {
      fullname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      privacy: "",
      web: "Grupo IAN",
    },
    loading: false,
    sent: false,
  }),
  methods: {
    ...mapActions(["sendContact"]),
    validate() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.sendContact(this.data).then((r) => {
            this.loading = false;
            if (r) {
              this.sent = true;
              this.data = {
                fullname: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
                privacy: "",
                web: "Grupo IAN",
              };
              this.$refs.form.reset();
            }
          });
        }
      });
    },
  },
};
</script>
