<template lang="html">
  <section class="banner-mapa">
    <h2 class="main-title waypoints">{{ $t('contact.map.title') }}</h2>
    <div class="contenedor-mapa">
      <l-map id="map" ref="map" :style="{ 'height': '560px', 'width': '100%' }" :center="centerMap" :zoom="6" :minZoom="6"
        :maxZoom="18" :zoomAnimation="true" :markerZoomAnimation="true">
        <l-tile-layer :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'" :attribution="'@OpenStreetMap'"
          layer-type="base" />
        <l-marker v-for="(location) in $t('contact.map.locations')" :ref="location.id" :key="location.id"
          :lat-lng="[location.pos.lat, location.pos.lng]" @click="selectLocation(location, false)">
          <l-icon :icon-size="[37, 37]">
            <img src="/img/marker.svg" />
          </l-icon>
          <l-popup>
            <div class="info-window">
              <h2>{{ location.name }}</h2>
              <h2>{{ location.subname }}</h2>
              <p class="direccion">{{ location.address }}</p>
              <p class="telefono" v-for="(phone, index) in location.phone" :key="'phone' + index">
                <a :href="'tel:' + phone">{{ phone }}</a>
              </p>
              <p class="email" v-for="(email, index) in location.email" :key="'email' + index">
                <a :href="'mailto:' + email">{{ email }}</a>
              </p>
            </div>
          </l-popup>
        </l-marker>
      </l-map>

      <div class="delegaciones waypoints">
        <div class="delegaciones-list">
          <article class="row-start" v-for="(location) in $t('contact.map.locations')" :key="location.id"
            :active="active == location.id" @click="selectLocation(location, true)">
            <div class="contenedor-imagen">
              <img :src="`/img/mapa-${location.id}.svg`" alt="">
            </div>
            <div class="texto">
              <h2>{{ location.name }}<br>{{ location.subname }}</h2>
            </div>
            <a class="enlace-cover"></a>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
export default {
  name: 'mapa-contact',
  props: [],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
  },
  data() {
    return {
      active: null,
    }
  },
  methods: {
    selectLocation(location, openPopup) {
      this.active = location.id;
      if (openPopup) {
        this.$refs[location.id][0].mapObject.openPopup();
      }
      this.$refs.map.mapObject.setView({ lat: location.pos.lat, lng: location.pos.lng }, 10, { animate: true, duration: 1 });
    }
  },
  computed: {
    centerMap() {
      let pos = [40.442, -8.721]
      if (screen.width < 700) {
        pos = [40.42114, -3.7077865]
      }
      return pos
    }
  }
}
</script>