<template>
    <div class="wrapper -contacto padding-menu -fondo-campo">
        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link to="/" style="pointer-events: none;">{{ $t('navbar_menu.contact.title') }}</router-link>
                <router-link :to="$route.path">{{ $t('navbar_menu.contact.sub_groups.contacto.title') }}</router-link>
            </div>
        </div>

        <section class="intro-page">
            <div class="container">
                <h1 class="waypoints">{{ $t('contact.title') }}</h1>
                <img class="waypoints" :src="$t('contact.main_banner.img')" alt="img main banner">
                <div class="green-box">
                    <h2 v-html="$t('contact.main_banner.title')"></h2>
                    <div v-html="$t('contact.main_banner.text')"></div>
                </div>
            </div>
        </section>

        <mapa-contact></mapa-contact>

        <section class="formulario-container">
            <div class="container row-center">
                <h2 class="waypoints">{{ $t('contact.form.title') }}</h2>
                <div v-html="$t('contact.form.text')"></div>
                <formulario></formulario>
            </div>
        </section>

        <section class="imagen-texto">
            <div class="container row-start">
                <img :src="$t('contact.information_banner.img')" alt="" class="waypoints">
                <div class="texto">
                    <h2 class="main-title waypoints">{{ $t('contact.information_banner.title') }}</h2>
                    <div v-html="$t('contact.information_banner.text')"></div>
                    <a target="_blank" :href="$t('contact.information_banner.btn_href')" class="boton-verde waypoints">
                        {{ $t('contact.information_banner.btn_text') }}
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import mapaContact from '@/components/mapa-contact';
import formulario from '@/components/formulario-contacto';

export default {
    name: 'contacto',
    mounted() {
        setTimeout(() => {
            if (this.$route.hash == "#formulario") {
                const offsetTop = document.querySelector("#formulario").offsetTop;
                scroll({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
        }, 1000);
    },
    components: {
        'mapa-contact': mapaContact,
        'formulario': formulario
    }
}
</script>

<style lang="scss" scoped>
.intro-page {
    .container {
        padding-bottom: 50px;
    }

    img {
        width: 100%;
        max-width: 100%;
    }

    .green-box {
        bottom: 0;
        top: auto;
        transform: none;
    }
}

.imagen-texto {
    margin-top: 100px;

    img {
        max-width: calc(100% - 380px);
    }

    .texto {
        max-width: 380px;

        p {
            display: inline-block;
            width: 100%;
            margin-bottom: 20px;
        }

        .boton-verde {
            display: inline-block;
        }
    }
}

.green-box {
    h2 {
        font-size: 30px;
    }

    p {
        margin-bottom: 0;
    }
}

@media screen and (max-width:1000px) {
    .imagen-texto img {
        max-width: 100%;
    }
}

@media screen and (max-width:550px) {
    .green-box h2 {
        font-size: 24px;
    }
}
</style>